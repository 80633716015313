<template>
  <div class="container">
      <div class="backStyle" style="border-radius: 8px 0px 0px 0px;" @click="$router.push('/freeTrial')">
          <img src="@/assets/home/ys.png" alt="">
          <span class="backTopTip">预约演示</span>
      </div>
      <div class="backStyle" style="margin-top:5px" @click="$router.push('/home')">
          <img src="@/assets/home/fhsy.png" alt="">
          <span class="backTopTip">返回首页</span>
      </div>
      <div class="backStyle" style="margin-top:5px;border-radius: 0px 0px 0px 8px;" @click="backTopFn">
          <img src="@/assets/home/zhiding.png" alt="">
          <span class="backTopTip">返回顶部</span>
      </div>
  </div>
</template>

<script>
export default {
  name: 'BackTop',
  methods: {
    backTopFn () {
      var GoTopContent = this.$parent.$refs.GoTopContent

      const timer = setInterval(() => {
        if (GoTopContent.scrollTop <= 0) {
          GoTopContent.scrollTop = 0
          clearInterval(timer)
        }
        GoTopContent.scrollTop = GoTopContent.scrollTop - 10
      }, 1)
    }
  }
}
</script>

<style scoped lang='scss'>
.container{
  position:absolute;
  top: 35%;
  right: 15px;
  z-index: 10000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.backStyle{
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    background: rgba(255,255,255,0.9);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);

    img{
      width: 32px;
      height: 32px;
    }

    .backTopTip{
      font-size: 14px;
      padding-top: 4px;
      color: #444;
      font-weight: 400;
    }
  }
</style>
