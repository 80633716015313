<template>
  <div class="threeCard">
      <ul class="threeCardContent">
          <li v-for="(item,index) in threeCard" :key="index">
              <img :src="item.src" alt="">
              <h5 v-if="item.title">{{item.title}}</h5>
              <p v-for="(v,i) in item.desc" :key="i">{{v.p}}</p>
          </li>
      </ul>
  </div>
</template>

<script>
export default {
  props: {
    threeCard: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>
*{
    padding:0;
    margin: 0;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
img {
   object-fit: cover;
}
li {
  list-style: none;
}
.threeCard{
    width: 100%;
    padding-top: 60px;
}
.threeCardContent{
    display: flex;
    justify-content: space-between;
}
.threeCardContent li {
    width: 384px;
    height: 272px;
    display: flex;
    flex-direction: column;
    align-items: center;
background: #FFFFFF;
border-radius: 10px;
padding-top: 40px;
box-sizing: border-box;
}
.threeCardContent img{
    width: 68px;
}
.threeCardContent li h5{
font-size: 24px;
font-weight: 500;
color: #222222;
padding: 20px 0;
}
.threeCardContent li p{
    width: 304px;
font-size: 14px;
font-weight: 400;
color: #444444;
line-height: 24px;
text-align: center;
}
</style>
