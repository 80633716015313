import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const option = {
  state: {

  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
}

export default new Vuex.Store(option)
