<template>
  <div class="SlideshowContainer">
    <el-carousel indicator-position="outside" :interval="5000">
        <el-carousel-item v-for="(item,index) in slideshow" :key="index">
            <div class="left cared">
                <img :src="item.left.src" alt="">
                <div class="contentDesc">
                    <h5>{{item.left.title}}</h5>
                <p>{{item.left.p}}</p>
                </div>
            </div>
            <div class="right cared">
                <img :src="item.right.src" alt="">
                <div class="contentDesc">
                <h5>{{item.right.title}}</h5>
                <p>{{item.right.p}}</p>
                </div>
            </div>
        </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'Slideshow',
  props: {
    slideshow: {
      ttype: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
img {
    object-fit: cover;
}
.SlideshowContainer{
width: 100%;
padding-top: 60px;
}
.SlideshowContainer ::v-deep .el-carousel__container {
  height: 350px;
}
.SlideshowContainer ::v-deep .el-carousel__item {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
 ::v-deep .el-carousel__button {
  width: 20px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.cared{
    background-color: #fff;
height: 330px;
}
.cared img{
    display: block;
}
.cared:hover{
box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
cursor: pointer;
}
.contentDesc{
    padding: 24px;
    box-sizing: border-box;
border: 1px solid rgba(211, 218, 228, 0.5);
border-top: 0;
}
</style>
