<template>
  <div class="showCard">
      <div class="showCardTop">
          <h4>{{cardContent.title}}</h4>
        <span class="line"></span>
        <p v-if="cardContent.p" style="color:#444;font-size:16px;margin-top:20px;text-align:center;line-height: 28px;width:1100px">{{cardContent.p}}</p>
      </div>
      <ul class="showCardContent">
          <li v-for="(item,index) in cardContent.content" :key="index" :style="item.position">
              <div class="left"><img :src="item.img" alt=""></div>
              <div class="right">
                  <h5>{{item.title}}</h5>
                  <p>{{item.desc}}</p>
              </div>
          </li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'showCard',
  props: {
    cardContent: {
      type: Object,
      default: () => {
        return null
      }
    }
  }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
img {
   object-fit: cover;
}
li{
    list-style: none;
}
.line {
display: inline-block;
width: 40px;
height: 6px;
background: #D70C0C;
border-radius: 3px;
}
.showCard{
    text-align: center;
}
.showCardTop{
margin-bottom: 58px;
display: flex;
flex-direction: column;
align-items: center;
}
.showCardTop h4{
    font-size: 36px;
    font-weight: 500;
    color: #222;
    padding: 60px 0 20px 0;
}
.showCardContent{
    position: relative;
    top: 0;
    left: 0;
    width: 1200px;
    height:calc(100% - 200px);
    margin: auto;
    /* background-color: red; */
}
.showCardContent li {
    position: absolute;
}
.showCardContent li {
    display: flex;
    justify-content: space-between;
    text-align: left;
}
.showCardContent .left{
    margin-right: 40px;
}
.showCardContent .left img{
      width: 68px;
    height: 68px;
}
.showCardContent .right h5{
    font-size: 24px;
    font-weight: 500;
    color: #222;
    padding-bottom: 10px;
}
.showCardContent .right p{
    font-size: 14px;
    color: #444;
    font-weight: 400;
    line-height: 24px;
}
</style>
