<template>
  <div class="container">
      <div class="FootContentMakr">
        <div style="width:100%;padding:0 20px;display: flex;flex-direction: column;align-items: center;">
          <div style="display: flex;align-items: center;">
              <img style="width: .875rem;height: .875rem;;margin-right: .5rem;" src="@/assets/Mobile/bottom/phone.png" alt="">
              <span class="address" style="margin-right:30px">010-60780718</span>
              <img style="width: .875rem;height: .875rem;;margin-right: .5rem;" src="@/assets/Mobile/bottom/emial.png" alt="">
              <span class="address">market@epxing.com</span>
          </div>
          <div style="display: flex;align-items: center;padding:20px 0">
              <img style="width: .875rem;height: .875rem;margin-right: .5rem;" src="@/assets/Mobile/bottom/address.png" alt="">
              <div class="address">北京市海淀区上地三街9号D座3(2)层D311</div>
          </div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="display: flex;flex-direction: column;align-items: center;">
            <img style="width: 5rem;margin-bottom:10px" src="@/assets/gzhErWeiMa.png" alt="">
            <p style="font-size: .75rem;color: rgba(255, 255, 255, 0.9);">关注公众号</p>
          </div>
          <div style="display: flex;flex-direction: column;align-items: center;padding-left:40px">
            <img style="width: 5rem;margin-bottom:10px" src="@/assets/component/solutErweima.png" alt="">
            <p style="font-size: .75rem;color: rgba(255, 255, 255, 0.9);">获取智慧人事解决方案</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'moBottom'
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
img {
   object-fit: cover;
}
.container {
    width: 100%;
    background: url('../assets/bg-foot.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 3.75rem;
}
.FootContentMakr{
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background: #0c0c0e80;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 2.8125rem 0;
}

.address{
    color: rgba(255, 255, 255, 0.8);
    font-size: .875rem;
    /* line-height: 1.375rem; */
}

</style>
