<template>
  <div id="app">
    <div>

      <router-view></router-view>

    </div>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style lang="scss">
html,body{
  margin: 0;
  padding: 0;
  min-width: 100%;
  overflow:auto;
}
body{
  overflow-x: auto;
    font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
*{
  box-sizing: border-box;
}
</style>
