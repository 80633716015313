<template>
  <div class="connectContent">
      <h4 class="descH4">联系我们</h4>
      <span class="line"></span>
      <ul class="connectDesc">
          <li v-for="(item,index) in connect" :key="index">
              <div class="circle">
                  <img :src="item.src" alt="">
              </div>
              <p>{{item.p}}</p>
          </li>
      </ul>
     <div class="map"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      connect: [
        { src: require('@/assets/about/phone.png'), p: '010-60780718' },
        { src: require('@/assets/about/email.png'), p: 'market@epxing.com' },
        { src: require('@/assets/about/site.png'), p: '北京市海淀区上地三街9号D座8（7）层D812-1' }
      ]
    }
  }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
li {
    list-style: none;
}
img {
    object-fit: cover;
}
.connectContent{
    width: 100%;
    height: 744px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.descH4{
font-size: 36px;
font-weight: 500;
color: #222222;
padding: 60px 0 20px;
}
.line{
  display: inline-block;
  width: 40px;
height: 6px;
background: #D70C0C;
border-radius: 3px;
}
.connectDesc{
    width: 1200px;
    display: flex;
    padding-top: 60px;
}
.connectDesc li {
   flex: 1;
   height: 125px;
    border-right: 1px solid #D8D8D8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between;
}
.connectDesc li p{
font-size: 16px;
font-weight: 400;
color: #121212;
line-height: 24px;
}
.connectDesc li:last-child{
    border: 0;
}
.circle{
    width: 80px;
height: 80px;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
}
.circle img{
width: 40px;
}
.map {
    width: 100%;
    height: 360px;
    margin-top: 65px;
    background: url('~@/assets/about/map.png') center no-repeat;
    background-size: cover;
}
</style>
