<template>
  <div class="introduceCare">
    <div v-for="(item, index) in fontDesc" :key="index">
      <div v-if="item.fontLeft" class="CareContent">
        <div class="left">
          <h4>{{ item.title }}</h4>
          <span class="introduceLine"></span>
          <div class="fontContent">
            <p v-for="(v, i) in item.p" :key="i">
              <span class="curle" v-if="v.show"></span>
              <span class="pTitle" v-if="v.tab">{{v.tab}}</span>
              {{ v.desc }}
            </p>
          </div>
          <button @click="$router.push('/freeTrial')">了解更多</button>
        </div>
        <div class="right">
          <img :src="item.src" alt="" />
        </div>
      </div>
      <div v-else class="CareContent">
        <div class="right">
          <img :src="item.src" alt="" />
        </div>
        <div class="left">
          <h4>{{ item.title }}</h4>
          <span class="introduceLine"></span>
          <div class="fontContent">
            <p v-for="(v, i) in item.p" :key="i">
              <span class="curle" v-if="v.show"></span>
              <span class="pTitle" v-if="v.tab">{{v.tab}}</span>
              {{ v.desc }}
              </p>
          </div>
          <button @click="$router.push('/freeTrial')">了解更多</button>
        </div>
      </div>
    <span class="fenge" v-if="index !== fontDesc.length - 1"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'introduceCare',
  props: {
    fontDesc: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
img {
  object-fit: cover;
}
.fenge {
  display: inline-block;
  width: 1200px;
  height: 1px;
  background-color: rgba(211, 218, 228, 0.5);
}
.introduceCare {
  width: 1200px;
}
.introduceLine {
  display: inline-block;
  width: 30px;
  height: 4px;
  background: #d70c0c;
}
.CareContent {
  display: flex;
  justify-content: space-between;
  padding: 60px 0;
}
.CareContent .left h4 {
  font-size: 30px;
  font-weight: 500;
  color: #222;
  padding-bottom: 10px;
}
.fontContent {
  text-align: left;
  width: 500px;
  padding: 40px 0;
  font-size: 16px;
  color: #444444;
  line-height: 30px;
}
.left button {
  width: 120px;
  height: 40px;
  background: #ffffff;
  border-radius: 30px;
  border: 1px solid rgba(211, 218, 228, 0.5);
}
.left button:hover {
  color: #d70c0c;
}
button:hover {
  cursor: pointer;
}
.CareContent img{
  width: 560px;
}
.curle{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #D70C0C;
  margin-right: 8px;
}
.pTitle{
  font-weight: 550;
  color: #222;
}
</style>
