<template>
  <div class="FootContainer">
      <div class="FootContent">
        <div class="FootContentMakr"></div>
        <!-- <div class="FootContentTop">
          <div class="FootContentTopMark">
            <ul class="FootContentTopDesc">
              <li v-for="(item,index) in FootContentTop" :key="index">
                <h6 style="font-size:28px;font-weight:500">{{item.top}}</h6>
                <p style="font-size:14px;padding-top:6px">{{item.bottom}}</p>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="FootContentCenter">
          <h5 style="font-size:48px;font-weight:600;letter-spacing: 1px;">聚焦智慧人力  &nbsp;&nbsp; 共创数智校园</h5>
          <p style="font-size:18px;font-wieght:500;padding:24px 0 40px">预约演示，请留下您的联系方式，我们会尽快联系您</p>
          <button @click="$router.push('/freeTrial')">联系我们</button>
        </div>
        <div class="FootContentBottom">
          <div class="FootContentBottomDesc">
            <div class="FootContentBottomLeft">
              <dl v-for="(item,index) in FootContentBottom" :key="index">
                <dt style="font-size:16px;padding-bottom:18px;font-weight: 500;color: rgba(255, 255, 255, 0.8);">{{item.title}}</dt>
                <dd v-for="(des,i) in item.dd" :key="i" @click="$router.push(des.path)">
                  <img :src="des.src" alt="" v-if="des.src" style="width:14px;height:14px;margin-right:8px">
                  {{des.p}}
                </dd>
              </dl>
              <div class="FootContentBottomRightContent">
                <img src="@/assets/gzhErWeiMa.png" alt="" style="width:100px;height:100px">
                <p style="font-size:14px;font-weight: 400;color: rgba(255, 255, 255, 0.6);padding-top:8px">关注公众号</p>
              </div>
              <div class="FootContentBottomRightContent">
                <img src="@/assets/component/solutErweima.png" alt="" style="width:100px;height:100px">
                <p style="font-size:14px;font-weight: 400;color: rgba(255, 255, 255, 0.6);padding-top:8px">
                  获取智慧人事解决方案
                </p>
                <!-- <p style="font-size:14px;font-weight: 400;color: rgba(255, 255, 255, 0.6);padding-top:8px">

                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="FootBottom">
        <p style="margin-right:42px">©2021 北京易普行科技有限公司</p>
        <div style=" display: flex;align-items: center;">
          <img src="../assets/policeBadge.png" alt="" style="width:20px;height:20px;margin-right:6px">
          <p>京ICP备17043519号</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  data () {
    return {
      FootContentTop: [
        { top: '50%', bottom: '双一流高校占有率' },
        { top: '300+', bottom: '高校用户' },
        { top: '7*24', bottom: '专业服务、及时响应' },
        { top: '安全可靠', bottom: '中立、安全、可靠' },
        { top: '低成本', bottom: '低代码开发实施' }
      ],
      FootContentBottom: [
        { title: '基础平台', dd: [{ p: '低代码开发平台', path: '/product/productPt' }, { p: '智慧人事服务大厅', path: '/product/productSmartDT' }, { p: '人力资源数据中心', path: '/product/productDataCenter' }] },
        { title: '热门产品', dd: [{ p: '招聘管理系统', path: '/product/productEmployment' }, { p: '考核与评估系统', path: '/product/productKhypg' }, { p: '基础人事系统', path: '/product/productBasePeople' }, { p: '薪酬福利系统', path: '/product/productPaySystem' }, { p: '岗位评聘系统', path: '/product/productPosition' }, { p: '离退休系统', path: '/product/productRetire' }] },
        { title: '解决方案', dd: [{ p: '高校智慧人事', path: '/solution/smartHr' }, { p: '医院智慧人事', path: '/solution/smartYy' }, { p: '高校后勤人事', path: '/solution/smartSchoolLoHr' }, { p: '岗位目标动态监测', path: '/solution/smartXyyjcg' }, { p: '学术能力智慧评估', path: '/solution/smartXsnl' }] },
        { title: '联系方式', dd: [{ p: '010-60780718', src: require('../assets/phone.png') }, { p: 'market@epxing.com', src: require('../assets/email.png') }, { p: '北京市海淀区上地三街9号D座3(2)层D311', src: require('../assets/site.png') }] }
      ]
    }
  }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
li {
  list-style: none;
}
img {
  object-fit: cover;
}
.FootContainer{
  box-sizing: border-box;
  min-width: 1200px;
}
.FootContent{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 648px;
  background: url('../assets/bg-foot.png') center no-repeat;
  background-size: cover;
}
.FootContentMakr{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 648px;
  background: rgba(40, 40, 45, 0.5);
}
.FootContentTopMark{
   position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  color: #fff;

}

.FootContentTop{
    position: relative;
  width: 100%;
  height: 120px;
backdrop-filter: blur(6px);
  background: rgba(40, 40, 45, 0.2);
}
.FootContentTopDesc{
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.FootContentTopDesc li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.FootContentCenter{
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 400px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.FootContentCenter button {
  width: 200px;
height: 60px;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
border-radius: 34px;
border: 0;
font-size: 16px;
font-weight: 500;
color: #fff;
}
.FootContentCenter button:hover{
box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.FootContentBottom{
  position: absolute;
  top: 400px;
  left: 0;
  width: 100%;
  height: 248px;
  backdrop-filter: blur(7px);
  background: rgba(40, 40, 45, 0.7);
  color: #fff;
}
.FootContentBottomDesc{
width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
}
.FootContentBottomLeft{
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.FootContentBottomLeft dd{
  font-size:14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  padding-bottom:10px;
  display: flex;
  align-items: center;
}
.FootContentBottomLeft dl:nth-child(2) {
  width:250px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.FootContentBottomLeft dl:nth-child(2) dt {
  width: 100%;
  height: 38.8px;
}
.FootContentBottomLeft dl:nth-child(2) dd{
  width: 50%;
  height: 30px;
}
.FootContentBottomLeft dd:hover{
cursor: pointer;
color: rgba(255, 255, 255, 0.8);
}
.FootContentBottomLeft dl:nth-child(4):hover dd{
cursor:auto;
color: rgba(255, 255, 255, 0.6);
}

.FootContentBottomRightContent{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FootBottom{
  width: 100%;
  height: 60px;
background: #202025;
display: flex;
justify-content: center;
align-items: center;
}
.FootBottom p {
font-size: 12px;
font-weight: 400;
color: rgba(255, 255, 255, 0.7);
line-height: 17px;
}
</style>
