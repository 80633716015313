<template>
  <div class="container">
      <div class="footLeft" @click="$router.push('/moShow')">免费试用</div>
      <a href="tel:010-60780718" class="footRight">电话咨询</a>
  </div>
</template>

<script>
export default {
  name: 'moFoot'
}
</script>

<style scoped>
.container{
    position: fixed;
    bottom: 0;
    left: 0;
width: 100%;
height: 3.75rem;
background-color: red;
display: flex;
z-index: 10000;
overflow-y: auto;
}
.footLeft,.footRight{
flex: 1;
font-size: 1rem;
display: flex;
justify-content: center;
align-items: center;
}
.footLeft{
background: #F0F0F0;
}
.footRight{
background: #D70C0C;
color:#fff;
}
</style>
