<template>
  <div class="container">
    <div :class="{containerNavTop:true,navScrool: active}">
      <div style="margin-left:40px">
        <img v-if="active" class="logo" src="../assets/epx-logo.png" alt="" />
        <img v-else  class="logo" src="../assets/logoWith.png" alt="" />
      </div>
      <div class="top">
        <ul class="nav">
          <li :class="{ colorRed: showIndex === 0,navLi:true }" @click="navHandle(0, '/home')">
            <span :style="{color: active?(showIndex === 0?'#d70c0c':'#222'):'#fff'}">首页</span>
            <span :class="{ line: showIndex === 0 }" :style="{'background-color':active?(showIndex === 0?'#d70c0c':''):'#fff'}"></span>
          </li>
          <li :class="{ colorRed: showIndex === 1,navLi:true }" @click="navHandle(1, null)">
            <span :style="{color: active?(showIndex === 1?'#d70c0c':'#222'):'#fff'}">产品服务</span>
            <span :class="{ line: showIndex === 1 }" :style="{'background-color':active?(showIndex === 1?'#d70c0c':''):'#fff'}"></span>
            <div class="product" ref="product">
              <div class="basePt">
                <h6 style=" padding: 20px 0 14px;font-size: 18px;font-weight: 500;color: #222222;">基础平台</h6>
                <ul class="basePtDesc">
                  <li @click="$router.push('/product/productPt')">
                    <img src="@/assets/home/PtApplication.png" alt="">
                    <p>低代码开发平台</p>
                  </li>
                  <li @click="$router.push('/product/productSmartDT')">
                    <img src="@/assets/home/PtServe.png" alt="">
                    <p>智慧人事服务大厅</p>
                  </li>
                  <li @click="$router.push('/product/productDataCenter')">
                    <img src="@/assets/home/PtdateCenter.png" alt="">
                    <p>人力资源数据中心</p>
                  </li>
                </ul>
              </div>
              <div>
                <h6 style=" padding: 20px 0 14px;font-size: 18px;font-weight: 500;color: #222222;">业务系统</h6>
                <div style="padding-top: 20px;border-top: 1px solid #d8d8d8;">
                  <div v-for="(item, index) in ywxtList" :key="index" style="float: left;">
                    <div v-for="(children,chilIndex) in item" :key="chilIndex" class="wisdomHrDescItem" @click.stop="$router.push(children.path)">
                       <p>{{ children.p }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h6 style=" padding: 20px 0 14px;font-size: 18px;font-weight: 500;color: #222222;">服务</h6>
                <div style="padding-top: 20px;border-top: 1px solid #d8d8d8;">
                  <div v-for="(item, index) in serveceList" :key="index" style="float: left;">
                    <div v-for="(children,chilIndex) in item" :key="chilIndex" class="wisdomHrDescItem" @click="$router.push(children.path)">
                       <p>{{ children.p }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </li>

          <li :class="{ colorRed: showIndex === 2 ,navLi:true}" @click="navHandle(2, null)">
            <span :style="{color: active?(showIndex === 2?'#d70c0c':'#222'):'#fff'}">解决方案</span>
            <span :class="{ line: showIndex === 2 }" :style="{'background-color':active?(showIndex === 2?'#d70c0c':''):'#fff'}"></span>
          <div class="solute">
              <ul style="width:100%">
                <li v-for="(item,index) in solute" :key="index" @click="$router.push(item.path)">{{item.title}}</li>
              </ul>
            </div>
          </li>

          <li :class="{ colorRed: showIndex === 3 ,navLi:true}" @click="navHandle(3, '/typicalCase/typeCase')">
            <span :style="{color: active?(showIndex === 3?'#d70c0c':'#222'):'#fff'}">经典案例</span>
            <span :class="{ line: showIndex === 3 }" :style="{'background-color':active?(showIndex === 3?'#d70c0c':''):'#fff'}"></span>
            <!-- <div class="typicalCase">
              <ul>
                <li v-for="(item,index) in typicalCase" :key="index" @click="$router.push(item.path)">{{item.title}}</li>
              </ul>
            </div> -->
          </li>
          <li :class="{ colorRed: showIndex === 4 ,navLi:true}" @click="navHandle(4, '/news')">
            <span :style="{color: active?(showIndex === 4?'#d70c0c':'#222'):'#fff'}">新闻动态</span>
            <span :class="{ line: showIndex === 4 }" :style="{'background-color':active?(showIndex === 4?'#d70c0c':''):'#fff'}"></span>
          </li>
          <li :class="{ colorRed: showIndex === 5 ,navLi:true}" @click="navHandle(5, '/about')">
            <span :style="{color: active?(showIndex === 5?'#d70c0c':'#222'):'#fff'}">关于我们</span>
            <span :class="{ line: showIndex === 5 }" :style="{'background-color':active?(showIndex === 5?'#d70c0c':''):'#fff'}"></span>
          </li>
          <li :class="{ colorRed: showIndex === 6 ,navLi:true}" @click="navHandle(6, '/relationUs')">
            <span :style="{color: active?(showIndex === 6?'#d70c0c':'#222'):'#fff'}">联系我们</span>
            <span :class="{ line: showIndex === 6 }" :style="{'background-color':active?(showIndex === 6?'#d70c0c':''):'#fff'}"></span>
          </li>
        </ul>
      </div>
      <div class="titlePhone" :style="{marginRight:'40px',color:active?'#222':'#fff'}" >
        <img v-if="active" src="@/assets/component/phone.png" alt="" style="width:18px;marginRight:5px">
        <img v-else src="@/assets/component/phoneW.png" alt="" style="width:18px;marginRight:5px">
        <span style="font-size:15px">010-60780718</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  watch: {
    $route: {
      handler: function (val) {
        // console.log(val)
        var index = -1
        this.nav.forEach((item) => {
          if (val.path.includes(item.path)) {
            index = item.index
          }
        })
        this.showIndex = index
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: false,
      //  { index: 2, path: '/serviceAndSupport' },, { index: 4, path: '/cooperation' },
      nav: [{ index: 0, path: '/home' }, { index: 1, path: '/product' }, { index: 2, path: '/solution' }, { index: 3, path: '/typicalCase' }, { index: 4, path: '/news' }, { index: 5, path: '/about' }, { index: 6, path: '/relationUs' }],
      showIndex: 0,
      ywxtList: [
        [
          { p: '招聘管理系统', path: '/product/productEmployment' },
          { p: '基础人事系统', path: '/product/productBasePeople' },
          { p: '师资培养与发展系统', path: '/product/productSzpyyfzxt' },
          { p: '薪酬福利管理系统', path: '/product/productPaySystem' },
          { p: '岗位评聘系统', path: '/product/productPosition' },
          { p: '考核与评估系统', path: '/product/productKhypg' }

        ],
        [
          { p: '高层次人才管理系统', path: '/product/productHeight' },
          { p: '博士后管理系统', path: '/product/productBsh' },
          { p: '学者论坛管理系统', path: '/product/productXzlt' },
          { p: '投票表决系统', path: '/product/productVote' },
          { p: '目标责任制考核系统', path: '/product/productMbzrz' },
          { p: '组干与党务管理系统', path: '/product/productZgydwgl' }
        ],
        [
          { p: '离退休系统', path: '/product/productRetire' },
          { p: '统战管理系统', path: '/product/productUnite' },
          { p: '自助打印系统', path: '/product/productPrint' },
          { p: '人力资源决策分析系统', path: '/product/productPeer' }

        ]
      ],
      serveceList: [
        [
          { p: '同行评议云服务', path: '/product/productThps' },
          { p: '人事人才培训服务', path: '/product/productRsrcpxfw' },
          { p: '应用开发服务', path: '/product/productYykffw' }
        ]

      ],
      cooperation: [
        { title: '云厂商合作', path: '/cooperation/partnership' },
        { title: '渠道合作', path: '/cooperation/channelShip' },
        { title: '生态合作', path: '/cooperation/ecologicalShip' }
      ],
      solute: [
        { title: '高校智慧人事', path: '/solution/smartHr' },
        { title: '医院智慧人事', path: '/solution/smartYy' },
        { title: '高校后勤人事', path: '/solution/smartSchoolLoHr' },
        { title: '岗位目标动态监测', path: '/solution/smartXyyjcg' },
        { title: '学术能力智慧评估', path: '/solution/smartXsnl' }
      ],
      typicalCase: [
        // { title: '典型客户', path: '/typicalCase/customer' },
        { title: '经典案例', path: '/typicalCase/typeCase' }
      ]
    }
  },
  created () {
    if (this.isActive) {
      this.active = true
    }
  },
  mounted () {
    var GoTopContent = this.$parent.$refs.GoTopContent
    if (GoTopContent) {
      GoTopContent.addEventListener('scroll', this.handleScroll, true)
    }
  },
  beforeDestroy () {
    var GoTopContent = this.$parent.$refs.GoTopContent
    if (GoTopContent) {
      GoTopContent.removeEventListener('scroll', this.handleScroll, true)
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollTop)
  },
  methods: {
    navHandle (index, path) {
      if (!path) {
        return null
      }
      this.showIndex = index

      this.$router.push(path)
    },
    scrollTop () {
      console.log(window)
      if (window.pageYOffset > 1) {
        this.active = true
      } else {
        this.active = false
      }
    },
    handleScroll () {
      if (this.isActive) {
        return
      }
      var GoTopContent = this.$parent.$refs.GoTopContent
      if (GoTopContent.scrollTop <= 0) {
        this.active = false
      } else {
        this.active = true
      }
    }
  }
}
</script>

<style scoped>
.container{
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
li {
  list-style: none;
}

.containerNavTop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  z-index: 1000;
}
.navScrool {
  backdrop-filter: blur(10px);
  background: rgba(255,255,255,.7);
}
.top {
  position: relative;
  display: flex;
  width: 1200px;
  height: 80px;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 1000;
}
.top button {
  width: 110px;
  height: 36px;
  font-size: 16px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 20px;
  border: 0;
  color: #fff;
  font-weight: 400;
}
button:hover {
  cursor: pointer;
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.logo {
  height: 40px;
}

.nav .navLi {
  float: left;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  margin-right: 40px;
  height: 80px;
  color: #333;
  padding-top: 27px;
  box-sizing: border-box;
}

.nav li:hover {
  color: #d70c0c;
  cursor: default;
}
.nav .colorRed {
  color: #d70c0c;
}

.line {
  display: inline-block;
  width: 100%;
  height: 4px;
  background-color: #d70c0c;
  border-radius: 2px;
  position: absolute;
  top: 77px;
  left: 0;
}
.product {
  position: absolute;
  top: 80px;
  left: -130px;
  width: 980px;
  height: 0px;
  padding: 0 40px;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  background: rgb(247, 245, 244);
  overflow: hidden;
  transition: 0.5s;
  /* background: rgba(40, 40, 45, 0.7); */

}
.nav li:nth-child(2):hover .product{
  height: 345px;
  display: flex;
  opacity: 1 !important;
}

.basePt {
  width: 200px;
}

.basePt h6 {
  padding-top: 20px;
}
.basePtDesc {
  padding-top: 20px;
  border-top: 1px solid #d8d8d8;
}
.basePtDesc li {
  position: relative;
  padding: 0;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #202025;

}

.basePtDesc li:hover{
  color: #fff;
  cursor: pointer;
  /* opacity: 1; */
}
.basePtDesc img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.basePtDesc li:hover img{
  opacity: 0.9;
}
.basePtDesc p{
z-index: 1000;
}
.wisdomHrDesc {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #d8d8d8;
}
.wisdomHrDescItem {
  width: 160px;
  height: 24px;
  /* margin-right: 10px; */
  color: #222;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.wisdomHrDescItem:hover {
  color: #d70c0c;
  cursor: pointer;
}
.wisdomHrDesc li img {
  width: 24px;
  margin-right: 8px;
}

.cooperation{
  position: absolute;
  top: 80px;
  left: -20px;
  width: 120px;
  height: 150px;
  display: none;
  background-color: #fff;
box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
padding-top: 20px;

}
/* .nav li:nth-child(5):hover .cooperation{
display: block;
  transition: all 4s;
} */
.cooperation li ,.solute li{
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #222;
  text-align: center;
}

.solute{
  position: absolute;
  top: 80px;
  left: -35px;
  width: 150px;
  display: none;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  opacity: 0;
  display: flex;
  height: 0px;
  overflow: hidden;
  /* height: 180px; */
  transition: 0.5s;
}

.nav li:nth-child(3):hover .solute{
  height: 220px;
  opacity: 1 !important;
}

.typicalCase{
  position: absolute;
  top: 80px;
  left: -20px;
  width: 120px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  opacity: 0;
  display: block;
  height: 0px;
  transition: 0.5s;
  overflow: hidden;

}
.nav li:nth-child(4):hover .typicalCase{
  height: 100px;
  opacity: 1 !important;
}

.typicalCase ul{
  width: 100%;
  height: 100%;
}
.typicalCase li {
font-size: 14px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #222;
}

.cooperation li:hover ,.solute li:hover ,.typicalCase li:hover{
  cursor: pointer;
}
.typicalCase li{

}

.titlePhone{
  min-width: 150px;
  display: flex;
  align-items: center;
}
</style>
