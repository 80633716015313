import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from 'axios'

import connectUs from './components/connectUs.vue'
import introduceCare from './components/introduceCare.vue'
import showCard from './components/showCare.vue'
import slideshow from './components/slideshow.vue'
import threeCard from './components/threeCard.vue'
import foot from '@/components/foot.vue'
import MoBottom from '@/components/moBottom'
import MoFoot from '@/components/moFoot'
import Title from '@/components/title'
import BackTop from '@/components/backTop.vue'

Vue.config.productionTip = false
Vue.use(ElementUI).use(Vant)
Vue.prototype.$axios = axios
Vue.prototype.baseUrl = 'https://pre.epxing.com:90/epxing-frame/api/v1/epxingos/gwglxt'

Vue.component('IntroduceCare', introduceCare)
Vue.component('Title', Title)
Vue.component('BackTop', BackTop)
Vue.component('ShowCard', showCard)
Vue.component('Slideshow', slideshow)
Vue.component('ConnectUs', connectUs)
Vue.component('ThreeCard', threeCard)
Vue.component('Foot', foot)
Vue.component('MoBottom', MoBottom)
Vue.component('MoFoot', MoFoot)

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
