import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const product = [
  {
    path: '/product/productPt',
    name: 'ProductPt',
    component: () => import(/* webpackChunkName: "ProductPt" */ '@/views/Product/ProductPt.vue')
  },
  {
    path: '/product/productDataCenter',
    name: 'ProductDataCenter',
    component: () => import(/* webpackChunkName: "ProductDataCenter" */ '@/views/Product/ProductDataCenter.vue')
  },
  {
    path: '/product/productSmartDT',
    name: 'ProductSmartDT',
    component: () => import(/* webpackChunkName: "ProductSmartDT" */ '@/views/Product/ProductSmartDT.vue')
  },
  {
    path: '/product/productPaySystem',
    name: 'ProductPaySystem',
    component: () => import(/* webpackChunkName: "ProductPaySystem" */ '@/views/Product/ProductPaySystem.vue')
  },
  {
    path: '/product/productBasePeople',
    name: 'ProductBasePeople',
    component: () => import(/* webpackChunkName: "ProductBasePeople" */ '@/views/Product/ProductBasePeople.vue')
  },
  {
    path: '/product/productVote',
    name: 'ProductVote',
    component: () => import(/* webpackChunkName: "ProductVote" */ '@/views/Product/ProductVote.vue')
  },
  {
    path: '/product/productEmployment',
    name: 'ProductEmployment',
    component: () => import(/* webpackChunkName: "ProductEmployment" */ '@/views/Product/ProductEmployment.vue')
  },
  {
    path: '/product/productUnite',
    name: 'ProductUnite',
    component: () => import(/* webpackChunkName: "ProductUnite" */ '@/views/Product/ProductUnite.vue')
  },
  {
    path: '/product/productRetire',
    name: 'ProductRetire',
    component: () => import(/* webpackChunkName: "ProductRetire" */ '@/views/Product/ProductRetire.vue')
  },
  {
    path: '/product/productPeer',
    name: 'ProductPeer',
    component: () => import(/* webpackChunkName: "ProductPeer" */ '@/views/Product/ProductPeer.vue')
  },
  {
    path: '/product/productPosition',
    name: 'ProductPosition',
    component: () => import(/* webpackChunkName: "ProductPosition" */ '@/views/Product/ProductPosition.vue')
  },
  {
    path: '/product/productPrint',
    name: 'ProductPrint',
    component: () => import(/* webpackChunkName: "ProductPrint" */ '@/views/Product/ProductPrint.vue')
  },
  {
    path: '/product/productMbzrz',
    name: 'ProductMbzrz',
    component: () => import(/* webpackChunkName: "ProductMbzrz" */ '@/views/Product/ProductMbzrz.vue')
  },
  {
    path: '/product/productZgydwgl',
    name: 'ProductZgydwgl',
    component: () => import(/* webpackChunkName: "ProductZgydwgl" */ '@/views/Product/ProductZgydwgl.vue')
  },
  {
    path: '/product/productKhypg',
    name: 'ProductKhypg',
    component: () => import(/* webpackChunkName: "ProductKhypg" */ '@/views/Product/ProductKhypg.vue')
  },
  {
    path: '/product/productHeight',
    name: 'ProductHeight',
    component: () => import(/* webpackChunkName: "ProductHeight" */ '@/views/Product/ProductHeight.vue')
  },
  {
    path: '/product/productBsh',
    name: 'ProductBsh',
    component: () => import(/* webpackChunkName: "ProductBsh" */ '@/views/Product/ProductBsh.vue')
  },
  {
    path: '/product/productXzlt',
    name: 'ProductXzlt',
    component: () => import(/* webpackChunkName: "ProductXzlt" */ '@/views/Product/ProductXzlt.vue')
  },
  {
    path: '/product/productThps',
    name: 'ProductThps',
    component: () => import(/* webpackChunkName: "ProductThps" */ '@/views/Product/ProductThps.vue')
  },
  {
    path: '/product/productSzpyyfzxt',
    name: 'ProductSzpyyfzxt',
    component: () => import(/* webpackChunkName: "ProductSzpyyfzxt" */ '@/views/Product/ProductSzpyyfzxt.vue')
  },
  {
    path: '/product/productRsrcpxfw',
    name: 'ProductRsrcpxfw',
    component: () => import(/* webpackChunkName: "ProductRsrcpxfw" */ '@/views/Product/ProductRsrcPxfw.vue')
  },
  {
    path: '/product/productYykffw',
    name: 'ProductYykffw',
    component: () => import(/* webpackChunkName: "ProductYykffw" */ '@/views/Product/ProductYykffw.vue')
  }
]

const solute = [
  {
    path: '/solution/smartHr',
    name: 'SmartHr',
    component: () => import(/* webpackChunkName: "SmartHr" */ '../views/Solute/SmartHr.vue')
  },
  {
    path: '/solution/smartYy',
    name: 'SmartYy',
    component: () => import(/* webpackChunkName: "SmartYy" */ '../views/Solute/SmartYy.vue')
  },
  {
    path: '/solution/smartXyyjcg',
    name: 'SmartXyyjcg',
    component: () => import(/* webpackChunkName: "SmartXyyjcg" */ '../views/Solute/SmartXyyjcg.vue')
  },
  {
    path: '/solution/smartXsnl',
    name: 'SmartXsnl',
    component: () => import(/* webpackChunkName: "SmartXsnl" */ '../views/Solute/SmartXsnl.vue')
  },
  {
    path: '/solution/smartSchoolLoHr',
    name: 'SmartSchoolLoHr',
    component: () => import(/* webpackChunkName: "SmartSchoolLoHr" */ '../views/Solute/SmartSchoolLoHr.vue')
  }
]

const typicalCase = [
  {
    path: '/typicalCase/customer',
    name: 'Customer',
    component: () => import(/* webpackChunkName: "Customer" */ '@/views/TypicalCase/Customer.vue')
  },
  {
    path: '/typicalCase/typeCase',
    name: 'TypeCase',
    component: () => import(/* webpackChunkName: "TypeCase" */ '@/views/TypicalCase/TypeCase.vue')
  },
  {
    path: '/typicalCase/zhongSan',
    name: 'ZhongSan',
    component: () => import(/* webpackChunkName: "ZhongSan" */ '@/views/TypicalCase/TypeCase/zhongsan.vue')
  },
  {
    path: '/typicalCase/sanDong',
    name: 'SanDong',
    component: () => import(/* webpackChunkName: "SanDong" */ '@/views/TypicalCase/TypeCase/sandong.vue')
  }
]

const moProduct = [
  {
    path: '/moProduct/moPT',
    name: 'moPT',
    component: () => import(/* webpackChunkName: "moPT" */ '@/views/MoProduct/moPT.vue')
  },
  {
    path: '/moProduct/moSmartDT',
    name: 'moSmartDT',
    component: () => import(/* webpackChunkName: "moSmartDT" */ '@/views/MoProduct/moSmartDt.vue')
  },
  {
    path: '/moProduct/moDataCenter',
    name: 'moDataCenter',
    component: () => import(/* webpackChunkName: "moDataCenter" */ '@/views/MoProduct/MoDataCenter.vue')
  },
  {
    path: '/moProduct/moPaySystem',
    name: 'moPaySystem',
    component: () => import(/* webpackChunkName: "moPaySystem" */ '@/views/MoProduct/MoPaySystem.vue')
  },
  {
    path: '/moProduct/moBasePeople',
    name: 'moBasePeople',
    component: () => import(/* webpackChunkName: "moBasePeople" */ '@/views/MoProduct/MoBasePeople.vue')
  },
  {
    path: '/moProduct/moEmployment',
    name: 'moEmployment',
    component: () => import(/* webpackChunkName: "moEmployment" */ '@/views/MoProduct/MoEmployMent.vue')
  },
  {
    path: '/moProduct/moPeer',
    name: 'moPeer',
    component: () => import(/* webpackChunkName: "moPeer" */ '@/views/MoProduct/moPeer.vue')
  },
  {
    path: '/moProduct/moPosition',
    name: 'moPosition',
    component: () => import(/* webpackChunkName: "moPosition" */ '@/views/MoProduct/moPosition.vue')
  },
  {
    path: '/moProduct/moPrint',
    name: 'moPrint',
    component: () => import(/* webpackChunkName: "moPrint" */ '@/views/MoProduct/moPrint.vue')
  },
  {
    path: '/moProduct/moRetire',
    name: 'moRetire',
    component: () => import(/* webpackChunkName: "moRetire" */ '@/views/MoProduct/moRetire.vue')
  },
  {
    path: '/moProduct/moUnite',
    name: 'moUnite',
    component: () => import(/* webpackChunkName: "moUnite" */ '@/views/MoProduct/moUnite.vue')
  },
  {
    path: '/moProduct/moVote',
    name: 'moVote',
    component: () => import(/* webpackChunkName: "moVote" */ '@/views/MoProduct/moVote.vue')
  },
  {
    path: '/moProduct/moProductHeight',
    name: 'moProductHeight',
    component: () => import(/* webpackChunkName: "MoHeight" */ '@/views/MoProduct/MoHeight.vue')
  },
  {
    path: '/moProduct/moProductZgydwgl',
    name: 'moProductZgydwgl',
    component: () => import(/* webpackChunkName: "MoZgydwgl" */ '@/views/MoProduct/MoZgydwgl.vue')
  },
  {
    path: '/moProduct/moProductBsh',
    name: 'moProductBsh',
    component: () => import(/* webpackChunkName: "MoBsh" */ '@/views/MoProduct/MoBsh.vue')
  },
  {
    path: '/moProduct/moProductSzpyyfzxt',
    name: 'moProductSzpyyfzxt',
    component: () => import(/* webpackChunkName: "MoSzpyyfzxt" */ '@/views/MoProduct/MoSzpyyfzxt.vue')
  },
  {
    path: '/moProduct/moProductXzlt',
    name: 'moProductXzlt',
    component: () => import(/* webpackChunkName: "MoXzlt" */ '@/views/MoProduct/MoXzlt.vue')
  },
  {
    path: '/moProduct/moProductThps',
    name: 'moProductThps',
    component: () => import(/* webpackChunkName: "MoThps" */ '@/views/MoProduct/MoThps.vue')
  },
  {
    path: '/moProduct/moProductKhypg',
    name: 'moProductKhypg',
    component: () => import(/* webpackChunkName: "MoKhypg" */ '@/views/MoProduct/MoKhypg.vue')
  },
  {
    path: '/moProduct/moProductMbzrz',
    name: 'moProductMbzrz',
    component: () => import(/* webpackChunkName: "MoMbzrz" */ '@/views/MoProduct/MoMbzrz.vue')
  },
  {
    path: '/moProduct/moProductRsrcpxfw',
    name: 'moProductRsrcpxfw',
    component: () => import(/* webpackChunkName: "moProductRsrcpxfw" */ '@/views/MoProduct/MoProductRsrcPxfw.vue')
  },
  {
    path: '/moProduct/moProductYykffw',
    name: 'moProductYykffw',
    component: () => import(/* webpackChunkName: "moProductYykffw" */ '@/views/MoProduct/MoProductYykffw.vue')
  }
]

const moTypicalCase = [
  {
    path: '/moCooperation/moCustomer',
    name: 'moCustomer',
    component: () => import(/* webpackChunkName: "moCustomer" */ '@/views/moTypicalCase/mocusTomer.vue')
  },
  {
    path: '/moCooperation/moTypicalcase',
    name: 'moTypicalcase',
    component: () => import(/* webpackChunkName: "moTypicalcase" */ '@/views/moTypicalCase/moTypicalcase.vue')
  },
  {
    path: '/moCooperation/moZhongSan',
    name: 'moZhongSan',
    component: () => import(/* webpackChunkName: "moZhongSan" */ '@/views/moTypicalCase/moTypicalCaseList/zhongSan.vue')
  },
  {
    path: '/moCooperation/moSanDong',
    name: 'moSanDong',
    component: () => import(/* webpackChunkName: "moSanDong" */ '@/views/moTypicalCase/moTypicalCaseList/moSandong.vue')
  }
]
const moSlution = [
  {
    path: '/moSlution/moSchool',
    name: 'moSchool',
    component: () => import(/* webpackChunkName: "moSchool" */ '@/views/moSlution/moSchool.vue')
  },
  {
    path: '/moSlution/moSmartYy',
    name: 'moSmartYy',
    component: () => import(/* webpackChunkName: "moSmartYy" */ '@/views/moSlution/moSmartYy.vue')
  },
  {
    path: '/moSlution/moSmartSchoolLoHr',
    name: 'moSmartSchoolLoHr',
    component: () => import(/* webpackChunkName: "moSchoolLoHr" */ '@/views/moSlution/moSchoolLoHr.vue')
  },
  {
    path: '/moSlution/moSmartXyyjcg',
    name: 'moSmartXyyjcg',
    component: () => import(/* webpackChunkName: "MoXyyjcg" */ '@/views/moSlution/MoXyyjcg.vue')
  },
  {
    path: '/moSlution/moAssess',
    name: 'moAssess',
    component: () => import(/* webpackChunkName: "moAssess" */ '@/views/moSlution/moAssess.vue')
  }
]

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
  },
  {
    path: '/mohome',
    name: 'MoHome',
    component: () => import(/* webpackChunkName: "MoHome" */ '@/views/MoHome.vue')
  },
  ...product,

  {
    path: '/relationUs',
    name: 'RelationUs',
    component: () => import(/* webpackChunkName: "RelationUs" */ '@/views/RelationUs.vue')
  },
  {
    path: '/moRelationUs',
    name: 'moRelationUs',
    component: () => import(/* webpackChunkName: "moRelationUs" */ '@/views/moRelationUs.vue')
  },
  ...solute,
  ...moSlution,
  ...typicalCase,
  ...moTypicalCase,
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue')
  },
  {
    path: '/moAbout',
    name: 'moAbout',
    component: () => import(/* webpackChunkName: "moAbout" */ '@/views/moAbout.vue')
  },
  {
    path: '/news',
    name: 'Journalism',
    component: () => import(/* webpackChunkName: "Journalism" */ '@/views/journalism.vue')
  },
  {
    path: '/moNews',
    name: 'moNews',
    component: () => import(/* webpackChunkName: "moNews" */ '@/views/moNews.vue')
  },
  {
    path: '/freeTrial',
    name: 'FreeTrial',
    component: () => import(/* webpackChunkName: "FreeTrial" */ '@/components/freeTrial.vue')
  },
  {
    path: '/moShow',
    name: 'moShow',
    component: () => import(/* webpackChunkName: "moShow" */ '@/views/moShow.vue')
  },
  {
    path: '/news/newContent',
    name: 'NewContent',
    component: () => import(/* webpackChunkName: "Journalism" */ '@/components/NewContent.vue'),
    meta: { scrollToTop: true }
  },
  {
    path: '/moNews/moNewText',
    name: 'moNewText',
    component: () => import(/* webpackChunkName: "moNewText" */ '@/views/moNewText.vue')
  },
  ...moProduct

]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  const isPc = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)

  // alert(from.fullPath)

  if (!isPc && !to.fullPath.includes('/mo')) {
    next('/mohome')
  }
  next()

  // else if (isPc && to.fullPath.includes('/mo')) {
  //   next('/home')
  // } else {
  //   next()
  // }
})
export default router
